* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html,
body,
.root,
.Main {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    max-height: 100vh;
    margin: 0;
}

.PagesSwitcher {
    width: 100%;
    padding: 16px;
    /* background-color: #f0f0f0; */
}

@media (min-width: 768px) {
    .PagesSwitcher {
        width: 80%;
        max-width: 1200px;
        margin: 0 auto;
    }
}